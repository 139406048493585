import { Component, OnInit, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LocationBaseDto, ManagementFeeTypeEnum, PatientBasicDto, PatientCasePresentationClient, PatientClient, PatientContractPlanDiscountDto, PatientContractPlanDto, PatientContractPlanFeeDto, PatientContractPlanInsuranceDto, PaymentSelectionTypeEnum, PifEnum } from 'src/app/shared/services/api.service';
import { AuthenticationService } from '../../../shared/services/auth';


@Component({
	selector: 'patient-case-presentation',
	templateUrl: './patient-case-presentation.component.html',
	styleUrls: ['./patient-case-presentation.component.css']
})
export class PatientCasePresentationComponent implements OnInit, OnDestroy {
	@ViewChild('editDownMonthsDialog', { static: false }) editDownMonthsDialog: TemplateRef<any>;
	private _destroy$: Subject<boolean> = new Subject<boolean>();
	dialogRef: MatDialogRef<any>;

	faSquare = faSquare;
	faCheckCircle = faCheckCircle;

	patient: PatientBasicDto;
	location: LocationBaseDto;

	treatmentList: PatientContractPlanDtoVM[]=[];
	selectedTreatmentPlans: PatientContractPlanDtoVM[] = [];
	
	selectedCustomizePlanTab: any = 'cash';
	selectedTreatmentPlan: PatientContractPlanDtoVM;
	isWorking: boolean;

	discounts: DiscountDtoVM[] = [];
	fees: FeeDtoVM[] = [];
	insurances: InsuranceDtoVM[] = [];
	editDown: number;
	editMonths: number;

	contactId: number;

	constructor(
		private patientCasePresentationClient: PatientCasePresentationClient,
		private patientClient: PatientClient,
		private _snackbar: MatSnackBar,
		private _authService: AuthenticationService,
		private _router: Router,
		public matDialog: MatDialog,
	) { }

	ngOnDestroy() {
		this._destroy$.next(true);
	}

	ngOnInit(): void {
		this.getPatientCasePresentation();
	}

	getPatientCasePresentation() {
		this.isWorking = true;
		this.patientCasePresentationClient.patientCasePresentation_GetOptions()
			.pipe(
				take(1),
				takeUntil(this._destroy$)
			)
			.subscribe(resp => {
				this.patient = resp.patient;
				this.location = resp.location;
				this.contactId = resp.settings.contactId
				resp.contractPlans.map(item => {
					this.treatmentList.push(new PatientContractPlanDtoVM(item));
				});
				this.selectedTreatmentPlans = this.treatmentList;
				this.calculateTotalFee();
				this.calculatePatientPortion();
				this.combineDiscounts();
				this.combineFees();
				this.combineInsurances();

				let selectedPlanIndex = this.selectedTreatmentPlans.findIndex(x => x.isSelected);
				this.selectPlan(selectedPlanIndex);
				this.isWorking = false;
		})
	}

	combineInsurances(): void {
		this.insurances = this.selectedTreatmentPlans[0].insurances;
		if (this.selectedTreatmentPlans[1] && this.selectedTreatmentPlans[1].insurances) {
			if (!this.insurances) this.insurances = [];
			this.selectedTreatmentPlans[1].insurances.forEach((d) => {
				let insurance = this.insurances.find(x => x.name == d.name);
				if (insurance) {
					insurance.amount2 = d.amount;
				}
				else {
					let insurance = {
						id: d.id,
						name: d.name,
						amount: 0,
						amount2: d.amount,
					};

					this.insurances.push(new InsuranceDtoVM(insurance));
				}
			});
		}
	}

	combineFees(): void {
		this.fees = this.selectedTreatmentPlans[0].fees;
		if (this.selectedTreatmentPlans[1] && this.selectedTreatmentPlans[1].fees) {
			if (!this.fees) this.fees = [];
			this.selectedTreatmentPlans[1].fees.forEach((d) => {
				let fee = this.fees.find(x => x.name == d.name);
				if (fee) {
					fee.flatAmount2 = d.flatAmount;
				}
				else {
					let fee = {
						id: d.id,
						name: d.name,
						flatAmount2: d.flatAmount,
					};

					this.fees.push(new FeeDtoVM(fee));
				}
			});
		}
	}

	combineDiscounts(): void {
		this.discounts = this.selectedTreatmentPlans[0].discounts;
		if (this.selectedTreatmentPlans[1] && this.selectedTreatmentPlans[1].discounts) {
			if (!this.discounts) this.discounts = [];
			this.selectedTreatmentPlans[1].discounts.forEach((d) => {
				let discount = this.discounts.find(x => x.name == d.name);
				if (discount) {
					discount.flatAmount2 = d.flatAmount;
					discount.percentAmount2 = d.percentAmount;
				}
				else {
					let discount = {
						id: d.id,
						name: d.name,
						flatAmount2: d.flatAmount,
						percentAmount2: d.percentAmount
					};

					this.discounts.push(new DiscountDtoVM(discount));
				}
			});
		}
	}

	selectTreatmentPlan(treatmentPlan){
		treatmentPlan.isSelected = treatmentPlan.isSelected ? false : true;

		if(treatmentPlan.isSelected){
			this.selectedTreatmentPlans.push(treatmentPlan);
		} else {
			let indx = this.selectedTreatmentPlans.findIndex(plan => plan.id == treatmentPlan.id);
			if(indx != -1){
				this.selectedTreatmentPlans.splice(indx, 1);
			}
		}

		if(this.selectedTreatmentPlans.length > 2){
			this.selectedTreatmentPlans[0].isSelected = false;
			this.selectedTreatmentPlans.splice(0, 1);
		}

	}

	selectCustomizePlanTab(tab){
		this.selectedCustomizePlanTab = tab;
	}

	selectPlan(indx) {
		if (!indx || indx < 0) indx = 0;

		this.selectedTreatmentPlans.map((item, index) => {
			if(index != indx){
				item.isSelected = false;
			}
		})

		this.selectedTreatmentPlan = this.selectedTreatmentPlans[indx];
		this.selectedTreatmentPlan.isSelected = true;
		this.changeCustomTreamentLengh(!!this.selectedTreatmentPlan.paymentTermsMonths ? this.selectedTreatmentPlan.paymentTermsMonths : this.selectedTreatmentPlan.treatmentLengthMonths);
		this.changeCustomAmountDown(!!this.selectedTreatmentPlan.amountDown ? this.selectedTreatmentPlan.amountDown : this.selectedTreatmentPlan.defaultDownAmount);

		switch (this.selectedTreatmentPlan.pifType) {
			case PifEnum.PifA:
				this.selectCustomizePlanTab('cash')
				break;
			case PifEnum.PifB:
				this.selectCustomizePlanTab('cc')
				break;
			default:
				this.selectCustomizePlanTab('officeFinance');
		}
	}

	calculatePatientPortion(){
		let adjustments_0 = 0;
		let adjustments_1 = 0;

		if (this.selectedTreatmentPlans[0]) {
			this.selectedTreatmentPlans[0].discounts.map(discount => {
				adjustments_0 += Number(discount.flatAmount);
			});

			this.selectedTreatmentPlans[0].insurances.map(insurance => {
				adjustments_0 += Number(insurance.amount);
			});

			this.selectedTreatmentPlans[0].patientPortion = Number(this.selectedTreatmentPlans[0].total) - adjustments_0;
		}

		if (this.selectedTreatmentPlans[1]) {
			this.selectedTreatmentPlans[1].discounts.map(discount => {
				adjustments_1 += Number(discount.flatAmount);
			});

			this.selectedTreatmentPlans[1].insurances.map(insurance => {
				adjustments_1 += Number(insurance.amount);
			});

			this.selectedTreatmentPlans[1].patientPortion = Number(this.selectedTreatmentPlans[1].total) - adjustments_1;
		}
	}

	calculateTotalFee(){
		let totalFees_0 = 0;
		let totalFees_1 = 0;

		if (this.selectedTreatmentPlans[0]) {
			this.selectedTreatmentPlans[0].fees.map(fee => {
				totalFees_0 += Number(fee.flatAmount);
			});

			this.selectedTreatmentPlans[0].total = Number(this.selectedTreatmentPlans[0].recordsFee) + Number(this.selectedTreatmentPlans[0].treatmentFee) + totalFees_0;
		}

		if (this.selectedTreatmentPlans[1]) {
			this.selectedTreatmentPlans[1].fees.map(fee => {
				totalFees_1 += Number(fee.flatAmount);
			});

			this.selectedTreatmentPlans[1].total = Number(this.selectedTreatmentPlans[1].recordsFee) + Number(this.selectedTreatmentPlans[1].treatmentFee) + totalFees_1;
		}
	}


	cashPatientPortion(discAmount) {
		let patientPortion = this.selectedTreatmentPlan.patientPortion - (discAmount / 100) * this.selectedTreatmentPlan.patientPortion;

		if (this.selectedTreatmentPlan.insuranceEstimates) {
			patientPortion = patientPortion + this.selectedTreatmentPlan.insuranceEstimates;
		}

		return patientPortion;
	}

	getDueNow(): number {
		let pifDiscount = this.selectedCustomizePlanTab == 'cc' ? this.selectedTreatmentPlan.pifBDiscountAmount : this.selectedTreatmentPlan.pifADiscountAmount;
		let dueNow = this.selectedTreatmentPlan.patientPortion - pifDiscount;
		return dueNow;
	}

	getPifTotalContract(): number {
		let insuranceEstimates = this.selectedTreatmentPlan.insuranceEstimates ? this.selectedTreatmentPlan.insuranceEstimates : 0;
		let patientPortion = this.selectedTreatmentPlan.patientPortion ? this.selectedTreatmentPlan.patientPortion : 0;
		let totalContract = patientPortion + insuranceEstimates;

		return totalContract;
	}

	paidInFullDiscount(discAmount){
		return (discAmount/100)*this.selectedTreatmentPlan.patientPortion;
	}

	changeCustomTreamentLengh(value, reCalculateMonthly?: boolean) {
		this.selectedTreatmentPlan.paymentTermsMonths = value;
		this.setManageFeeAmount();

		if (reCalculateMonthly) {
			this.calculateAmountMonthly();
		}
	}

	changeCustomAmountDown(value, reCalculateMonthly?: boolean) {
		this.selectedTreatmentPlan.amountDown = value;
		this.setManageFeeAmount();

		if (reCalculateMonthly) {
			this.calculateAmountMonthly();
		}
	}

	calculateAmountMonthly(): void {
		let paymentTobePaid = this.selectedTreatmentPlan.patientPortion - this.selectedTreatmentPlan.amountDown + this.selectedTreatmentPlan.managementFeeAmount;
		this.selectedTreatmentPlan.paymentAmountMonthly = Number(paymentTobePaid) / Number(this.selectedTreatmentPlan.paymentTermsMonths);
	}

	setManageFeeAmount(): void {
		if (this.selectedTreatmentPlan.paymentTermsMonths >= this.selectedTreatmentPlan.managementFeeThresholdInMonths) {
			let managementFeeAmount: number = 0;
			if (this.selectedTreatmentPlan.managementFeeType == ManagementFeeTypeEnum.Flat) {
				managementFeeAmount = this.selectedTreatmentPlan.managementRate;
			}
			else if (this.selectedTreatmentPlan.managementFeeType == ManagementFeeTypeEnum.Percent) {
				managementFeeAmount = (this.selectedTreatmentPlan.patientPortion - this.selectedTreatmentPlan.amountDown) * (this.selectedTreatmentPlan.managementRate / 100);
			}
			this.selectedTreatmentPlan.managementFeeAmount = managementFeeAmount;
		}
		else {
			this.selectedTreatmentPlan.managementFeeAmount = 0;
		}
	}

	interestFreeCalculation(){
		let insuranceEstimates = this.selectedTreatmentPlan.insuranceEstimates ? this.selectedTreatmentPlan.insuranceEstimates : 0;
		let managementFee = this.selectedTreatmentPlan.managementFeeAmount ? this.selectedTreatmentPlan.managementFeeAmount : 0;
		let patientPortion = this.selectedTreatmentPlan.patientPortion ? this.selectedTreatmentPlan.patientPortion : 0;
		let totalContract = patientPortion + insuranceEstimates;

		if (this.selectedTreatmentPlan.paymentTermsMonths >= this.selectedTreatmentPlan.managementFeeThresholdInMonths) {
			totalContract = totalContract + managementFee;
		}
		return totalContract;
	}

	treatmentChange(val: PatientContractPlanDtoVM): void {
		this.selectedTreatmentPlan = val;
	}

	notNow(): void {
		if (!this.selectedTreatmentPlan) return;
		this.isWorking = true;

		if (this.selectedCustomizePlanTab == 'cash')
			this.selectedTreatmentPlan.pifType = PifEnum.PifA;
		else if (this.selectedCustomizePlanTab == 'cc')
			this.selectedTreatmentPlan.pifType = PifEnum.PifB;
		else
			this.selectedTreatmentPlan.pifType = null;

		this.patientClient.patient_UpdatePatientPlanSelection(this.patient.id, this.selectedTreatmentPlan)
			.pipe(
				take(1),
				takeUntil(this._destroy$)
			)
			.subscribe(() => {
				this.isWorking = false;
				this._snackbar.open("Selection Saved", "OK");
			});
	}

	signAndPay(): void {
		if (!this.selectedTreatmentPlan) return;
		this.isWorking = true;

		if (this.selectedCustomizePlanTab == 'cash') {
			this.selectedTreatmentPlan.pifType = PifEnum.PifA;
			this.selectedTreatmentPlan.paymentSelectionType = PaymentSelectionTypeEnum.PaidInFull;
			this.selectedTreatmentPlan.bookkeepingDiscountAmount = this.selectedTreatmentPlan.pifADiscountAmount;
		}
		else if (this.selectedCustomizePlanTab == 'cc') {
			this.selectedTreatmentPlan.pifType = PifEnum.PifB;
			this.selectedTreatmentPlan.paymentSelectionType = PaymentSelectionTypeEnum.PaidInFull;
			this.selectedTreatmentPlan.bookkeepingDiscountAmount = this.selectedTreatmentPlan.pifBDiscountAmount;
		}
		else {
			this.selectedTreatmentPlan.pifType = null;
			this.selectedTreatmentPlan.paymentSelectionType = PaymentSelectionTypeEnum.PaymentPlan;
		}
		this._authService.setPaymentMethod(this.selectedTreatmentPlan.paymentSelectionType);
		this._authService.setContractPlanId(this.selectedTreatmentPlan.id);

		this.patientClient.patient_UpdatePatientPlanSelection(this.patient.id, this.selectedTreatmentPlan)
			.pipe(
				take(1),
				takeUntil(this._destroy$)
			)
			.subscribe(() => {
				this.isWorking = false;
				this._router.navigate(['/contracts/sign']);
			});
	}

	validateDown(): void {
		const minDown: number = this.selectedTreatmentPlan.eQuoteMinimumDownAmount ?
			this.selectedTreatmentPlan.eQuoteMinimumDownAmount :
			1;
		const maxDown: number = this.selectedTreatmentPlan.patientPortion;

		if (this.editDown < minDown || this.editDown > maxDown) {
			this._snackbar.open("Invalid down", "OK");
			return;
		}

		this.editMonths = Math.trunc(this.editMonths);

		const minMonths: number = this.selectedTreatmentPlan.eQuoteMinMonths ?
			this.selectedTreatmentPlan.eQuoteMinMonths :
			1;
		const maxMonths: number = this.selectedTreatmentPlan.eQuoteMaxMonths ?
			this.selectedTreatmentPlan.eQuoteMaxMonths :
			this.selectedTreatmentPlan.treatmentLengthMonths + 12;

		if (this.editMonths < minMonths || this.editMonths > maxMonths) {
			this._snackbar.open("Invalid number of months", "OK");
			return;
		}

		this.dialogRef.close(true);
	}

	editDownMonths():void {
		this.editDown = this.selectedTreatmentPlan.amountDown;
		this.editMonths = this.selectedTreatmentPlan.paymentTermsMonths;

		this.dialogRef = this.matDialog.open(this.editDownMonthsDialog);
		this.dialogRef.afterClosed().subscribe(dialogResponse => {
			if (dialogResponse) {
				this.selectedTreatmentPlan.amountDown = this.editDown;
				this.selectedTreatmentPlan.paymentTermsMonths = this.editMonths;
				this.changeCustomAmountDown(this.selectedTreatmentPlan.amountDown, true);
				this.changeCustomTreamentLengh(this.selectedTreatmentPlan.paymentTermsMonths, true);
			}
		})
	}
}

class PatientContractPlanDtoVM extends PatientContractPlanDto {
	patientPortion:number = 0;
}

class DiscountDtoVM extends PatientContractPlanDiscountDto {
	flatAmount2?: number;
	percentAmount2?: number;
}

class FeeDtoVM extends PatientContractPlanFeeDto {
	flatAmount2?: number;
}

class InsuranceDtoVM extends PatientContractPlanInsuranceDto {
	amount2?: number;
}
