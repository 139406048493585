import { Component, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter, OnDestroy, forwardRef, HostBinding, HostListener } from '@angular/core';
import { Subscription, Subject, fromEvent, Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'digital-signature',
    templateUrl: './digital-signature.component.html',
    styleUrls: ['./digital-signature.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DigitalSignatureComponent),
            multi: true
        }
    ]
})
export class DigitalSignatureComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
    //#region resize functions
    @HostListener('window:resize', ['$event'])
    onResize(eve) {
        if (this.canvas) {
            //resizing a canvas clears its contents so store as image, resize, then redraw
            //because of how this works, line stroke weight will not upscale or downscale properly
            //possible solution: store stroke coordinates and redraw based on that instead of pixel based manipulation
            let img = this._canvasContext.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
            this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth;
            this.canvas.nativeElement.height = this.canvas.nativeElement.width * this.sizeRatio;
            img = this.rescaleImage(img, this.canvas.nativeElement.width, this.canvas.nativeElement.height, 'nearest');
            this._canvasContext.putImageData(img, 0, 0);
        }
    }

    //"Borrowed" from https://github.com/LinusU/resize-image-data/blob/master/index.js
    //nearest neighbor interpolation, degrades quality quickly but simpler (aka faster)
    //bilinear interpolation, smoother rescaling but will cause bluring on repeated rescaling
    private rescaleImage(src: ImageData, width: number, height: number, scaleMethod: 'nearest' | 'bilinear' = 'nearest') {
        let dst = new ImageData(width, height);

        if (scaleMethod == 'nearest') {
            let pos = 0

            for (let y = 0; y < dst.height; y++) {
                for (let x = 0; x < dst.width; x++) {
                    const srcX = Math.floor(x * src.width / dst.width)
                    const srcY = Math.floor(y * src.height / dst.height)

                    let srcPos = ((srcY * src.width) + srcX) * 4

                    dst.data[pos++] = src.data[srcPos++] // R
                    dst.data[pos++] = src.data[srcPos++] // G
                    dst.data[pos++] = src.data[srcPos++] // B
                    dst.data[pos++] = src.data[srcPos++] // A
                }
            }
        } else if (scaleMethod == 'bilinear') {
            let pos = 0

            for (let y = 0; y < dst.height; y++) {
                for (let x = 0; x < dst.width; x++) {
                    const srcX = x * src.width / dst.width
                    const srcY = y * src.height / dst.height

                    const xMin = Math.floor(srcX)
                    const yMin = Math.floor(srcY)

                    const xMax = Math.min(Math.ceil(srcX), src.width - 1)
                    const yMax = Math.min(Math.ceil(srcY), src.height - 1)

                    dst.data[pos++] = this.interpolateVertical(src, 0, srcX, xMin, xMax, srcY, yMin, yMax) // R
                    dst.data[pos++] = this.interpolateVertical(src, 1, srcX, xMin, xMax, srcY, yMin, yMax) // G
                    dst.data[pos++] = this.interpolateVertical(src, 2, srcX, xMin, xMax, srcY, yMin, yMax) // B
                    dst.data[pos++] = this.interpolateVertical(src, 3, srcX, xMin, xMax, srcY, yMin, yMax) // A
                }
            }
        }

        return dst;
    }
    //#region bilinear interpolation functions
    private interpolate(k, kMin, kMax, vMin, vMax) {
        return Math.round((k - kMin) * vMax + (kMax - k) * vMin)
    }

    private interpolateHorizontal(src, offset, x, y, xMin, xMax) {
        const vMin = src.data[((y * src.width + xMin) * 4) + offset]
        if (xMin === xMax) return vMin

        const vMax = src.data[((y * src.width + xMax) * 4) + offset]
        return this.interpolate(x, xMin, xMax, vMin, vMax)
    }

    private interpolateVertical(src, offset, x, xMin, xMax, y, yMin, yMax) {
        const vMin = this.interpolateHorizontal(src, offset, x, yMin, xMin, xMax)
        if (yMin === yMax) return vMin

        const vMax = this.interpolateHorizontal(src, offset, x, yMax, xMin, xMax)
        return this.interpolate(y, yMin, yMax, vMin, vMax)
    }
    //#endregion
    //#endregion

    //#region Forms
    @HostBinding('attr.id') externalId = '';

    private _ID = '';
    @Input() set id(value: string) {
        this._ID = value;
        this.externalId = null;
    }
    get id() {
        return this._ID;
    }

    @Input('value') _value = '';
    get value() {
        return this._value;
    }
    set value(val) {
        this._value = val;
        if (this.canvas) {
            const img = new Image();
            img.src = val;
            fromEvent(img, 'load')
                .subscribe(evt => this._canvasContext.drawImage(img, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height));
        }
        this.onChange(val);
        this.onTouched();
    }

    onChange: any = () => { };
    registerOnChange(fn) {
        this.onChange = fn;
    }

    writeValue(value) {
        this.value = value;
    }

    onTouched: any = () => { };
    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    private disabled: boolean = false;
    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    @Input() required: boolean = false;
    //#endregion

    @Input('background') canvasBackground: string = '#FFF'; //background color of written signature area
    @Input('pen-size') penSize: number = 2;
    @Input('pen-color') penColor: string = '#000';
    @Input('size-ratio') sizeRatio: number = .25; //ratio of height compared to width
    private _generateTrigger: EventEmitter<any>;
    @Input('trigger') set generateTrigger(trigger: EventEmitter<any>) { //trigger passed in that will listen for when to generate the image from the canvas
        if (trigger == null)
            this._generateTrigger = new EventEmitter();
        else
            this._generateTrigger = trigger;
        if (this.subscription != null)
            this.subscription.unsubscribe();
        this.subscription = this._generateTrigger.subscribe(() => this.generateImage());
    }

    @Output('signatureImage') writtenSignature = new Subject<string>(); //event to listen for the the completed image generation after request
    @Output('signatureBlob') writtenSignatureBlob = new Subject<Blob>();

    @ViewChild('signatureCanvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
    private _canvasContext: CanvasRenderingContext2D
    private _drawing: boolean = false; //indicates whether canvas is currently being draw upon based upon mousedown or touchstart
    private _prevX: number;
    private _prevY: number;

    subscription: Subscription; //subscription storage for incoming trigger

    constructor() { }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this._canvasContext = this.canvas.nativeElement.getContext('2d');
        this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth;
        this.canvas.nativeElement.height = this.canvas.nativeElement.width * this.sizeRatio;
        //set up pen defaults here
        this._canvasContext.strokeStyle = this.penColor;
        this._canvasContext.lineWidth = this.penSize;
        this._canvasContext.lineCap = 'round';
        this._canvasContext.miterLimit = 0;
        this._canvasContext.lineJoin = 'round';
    }

    //generate data url image from canvas
    public generateImage(mimeType?: string, imageQuality?: any): Observable<Blob> {
        const isValid = this.trimSignature();
        if (isValid) {
            this.value = this.canvas.nativeElement.toDataURL(mimeType, imageQuality);
            this.writtenSignature.next(this.canvas.nativeElement.toDataURL(mimeType, imageQuality));
            this.canvas.nativeElement.toBlob((value: Blob) => this.writtenSignatureBlob.next(value));
            return new Observable(observer => {
                this.canvas.nativeElement.toBlob(value => {
                    observer.next(value);
                    observer.complete();
                }, mimeType, imageQuality)
            });
        }
        else {
            return new Observable(observer => {
                observer.next(null);
                observer.complete();
            });
        }
    }

    public generateTextImage(mimeType?: string, imageQuality?: any): Observable<Blob> {
        this.trimSignature();
        this.writtenSignature.next(this.canvas.nativeElement.toDataURL(mimeType, imageQuality));
        this.canvas.nativeElement.toBlob((value: Blob) => this.writtenSignatureBlob.next(value));
        return new Observable(observer => {
            this.canvas.nativeElement.toBlob(value => {
                observer.next(value);
                observer.complete();
            }, mimeType, imageQuality)
        });
    }

    public processTextImage(text: string, mimeType?: string, imageQuality?: any): void {
        this.clear();
        let ctx = this.canvas.nativeElement.getContext('2d');
        let defaultWidth: number = 96;
        ctx.font = `${defaultWidth}px Playball`;
        this.adjustTextWidth(ctx, defaultWidth, text);

        ctx.fillStyle = 'black'
        ctx.fillText(text, 0, 75);

        this.value = this.canvas.nativeElement.toDataURL(mimeType, imageQuality);
    }

    adjustTextWidth(ctx: CanvasRenderingContext2D, currentWidth: number, text: string): void {
        ctx.font = `${currentWidth}px Playball`;
        let textWidth = ctx.measureText(text).width;

        if (textWidth > this.canvas.nativeElement.width) {
            this.adjustTextWidth(ctx, currentWidth - 1, text);
        }
    }

    draw(event: MouseEvent | TouchEvent, type: string) {
        event.preventDefault();
        if (this.disabled)
            return;
        let pos = this.getPos(this.canvas.nativeElement, event); //find where mouse is currently
        switch (type) {
            case 'down': //start drawing
                this._canvasContext.beginPath();
                this._prevX = pos.x;
                this._prevY = pos.y;
                this._drawing = true;
                break;
            case 'up': //end drawing
            case 'out':
                this._drawing = false;
                this._canvasContext.closePath();
                break;
            case 'move': //continue drawing (if pen still down and above canvas)
                if (this._drawing) {
                    this._canvasContext.moveTo(this._prevX, this._prevY);
                    this._canvasContext.lineTo(pos.x, pos.y);
                    this._canvasContext.stroke();
                    this._prevX = pos.x;
                    this._prevY = pos.y;
                }
        }
    }

    //reset canvas to blank
    clear() {
        this._canvasContext.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }

    //get position of mouse or touch in relation to the canvas coords
    private getPos(canvas: HTMLCanvasElement, evt: MouseEvent | TouchEvent): { x: number; y: number } {
        let rect = canvas.getBoundingClientRect();
        if (evt instanceof MouseEvent) {
            return {
                x: (evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width,
                y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height
            };
        } else {
            //initial touch event is in evt.touches but as they move the position exists in evt.changedTouches
            let xNow = evt.touches.length > 0 ? evt.touches[0].clientX : evt.changedTouches[0].clientX;
            let yNow = evt.touches.length > 0 ? evt.touches[0].clientY : evt.changedTouches[0].clientY;
            return {
                x: (xNow - rect.left) / (rect.right - rect.left) * canvas.width,
                y: (yNow - rect.top) / (rect.bottom - rect.top) * canvas.height
            };
        }
    }

    private trimSignature(): boolean {
        let ctx = this.canvas.nativeElement.getContext("2d");
        let imgWidth = ctx.canvas.width;
        let imgHeight = ctx.canvas.height;
        let imageData = ctx.getImageData(0, 0, imgWidth, imgHeight),
            data = imageData.data,
            getAlpha = function (x, y) {
                return data[(imgWidth * y + x) * 4 + 3]
            },
            scanY = function (fromTop) {
                let offset = fromTop ? 1 : -1;

                // loop through each row
                for (let y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {

                    // loop through each column
                    for (let x = 0; x < imgWidth; x++) {
                        if (getAlpha(x, y)) {
                            return y;
                        }
                    }
                }
                return null; // all image is white
            },
            scanX = function (fromLeft) {
                let offset = fromLeft ? 1 : -1;

                // loop through each column
                for (let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {

                    // loop through each row
                    for (let y = 0; y < imgHeight; y++) {
                        if (getAlpha(x, y)) {
                            return x;
                        }
                    }
                }
                return null; // all image is white
            };

        let cropTop: number = scanY(true);
        let cropBottom: number = scanY(false);
        let cropLeft: number = scanX(true);
        let cropRight: number = scanX(false);

        if (cropTop == null
            || cropBottom == null
            || cropLeft == null
            || cropRight == null) {
            return false;
        }

        let relevantData = ctx.getImageData(cropLeft, cropTop, cropRight - cropLeft, cropBottom - cropTop);
        this.canvas.nativeElement.width = cropRight - cropLeft;
        this.canvas.nativeElement.height = cropBottom - cropTop;
        ctx.clearRect(0, 0, cropRight - cropLeft, cropBottom - cropTop);
        ctx.putImageData(relevantData, 0, 0);
        return true;
    };
}
