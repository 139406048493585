<mat-progress-bar mode="indeterminate" *ngIf="isWorking"></mat-progress-bar>
<ng-container *ngIf="patientForms">
    <div class="component-container">
        <ng-container *ngIf="!selectedPatientForm">
            <div class="title">
                Patient Forms
            </div>
            <div class="d-flex justify-content-center">
                <div class="form-list">
                    <div *ngFor="let patientForm of patientForms">
                        <span (click)="selectPatientForm(patientForm)"
                              class="form-list-item">
                            {{patientForm.formTypeName}}
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
        <form *ngIf="selectedPatientForm && formGroup"
              [formGroup]="formGroup"
              (ngSubmit)="submit()"
              novalidate>
            <div class="d-flex justify-content-center"
                 style="max-height: 85vh;">
                <div class="form-container"
                    style="padding:10px 30px"
                     #formContent>
                    <div *ngIf="stationaryHeader"
                         class="form-header"
                         [innerHTML]="stationaryHeader">
                    </div>

                    <div class="form-details">
                        <ng-container *ngIf="selectedFormType">
                            <div class="form-description"
                                 [innerHTML]="selectedFormType.description | safeHtml">
                            </div>
                            <div *ngFor="let formSection of selectedFormType.formSections; let pageIndex=index">
                                <ng-container *ngIf="(pageIndex+1) === page || isGeneratingPdf">
                                    <div class="form-section-label"
                                         [innerHTML]="formSection.sectionLabel | safeHtml">
                                    </div>
                                    <ng-template *ngTemplateOutlet="formItemTemplate; context: {formItems : formSection.formItems}">

                                    </ng-template>
                                </ng-container>
                            </div>

                            <ng-template #formItemTemplate
                                         let-formItems="formItems">
                                <div *ngFor="let formItem of formItems | orderPipe: 'order': true; let i=index;"
                                     [ngClass]="{
                                        'form-item': formItem.formDataType != formDataTypeEnum.CheckBox, 
                                        'form-item-checkbox': formItem.formDataType == formDataTypeEnum.CheckBox,
                                        'col-3': formItem.formDataType == formDataTypeEnum.CheckBox
                                    }">
                                    <ng-container *ngIf="formItem.formDataType == formDataTypeEnum.Container">
                                        <div class="form-item-paragraph"
                                             *ngIf="formItem.formDataType != formDataTypeEnum.CheckBox"
                                             [innerHTML]="formItem.paragraph | safeHtml">
                                        </div>
                                        <div class="form-item-container">
                                            <ng-template *ngTemplateOutlet="formItemTemplate; context: {formItems : formItem.children}">

                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="formItem.formDataType == formDataTypeEnum.Row">
                                        <div class="row">
                                            <ng-template *ngTemplateOutlet="formItemTemplate; context: {formItems : formItem.children}">

                                            </ng-template>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="formItem.formDataType != formDataTypeEnum.Row && formItem.formDataType != formDataTypeEnum.Container">
                                        <div [ngClass]="{'form-item-paragraph': formItem.formDataType != formDataTypeEnum.Radio}"
                                             *ngIf="formItem.formDataType != formDataTypeEnum.CheckBox"
                                             [innerHTML]="formItem.paragraph | safeHtml">
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Radio"
                                             class="full-width">
                                            <mat-radio-group aria-label="Select an option"
                                                             formControlName="{{formItem.formDataType}}_{{formItem.id}}">
                                                <ng-container *ngFor="let child of formItem.children | orderPipe: 'order': true; let i = index">
                                                    <mat-radio-button value="{{child.paragraph}}"
                                                                      style="padding-right:5px;">
                                                        <div [innerHTML]="child.paragraph | safeHtml"></div>
                                                    </mat-radio-button>
                                                </ng-container>
                                            </mat-radio-group>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Attachments">
                                            <mat-icon mat-icon-button
                                                      style="padding-top:20px; cursor:pointer;"
                                                      color="accent"
                                                      matTooltip="Attach Local File"
                                                      ngfSelect
                                                      multiple="1"
                                                      (filesChange)="localFileAttachmentChange($event, formItem)"
                                                      [files]="getLocalFileAttachment(formItem)">
                                                attach_file
                                            </mat-icon>

                                            <mat-list dense>
                                                <mat-list-item *ngFor="let localFileAttachment of getLocalFileAttachment(formItem); let i = index">
                                                    <span style="cursor: pointer;">{{localFileAttachment.name}}</span>
                                                    <mat-icon color="warn"
                                                              style="cursor:pointer"
                                                              class="remove-attachment"
                                                              (click)="removeLocalFileAttachment(formItem, i)"
                                                              matTooltip="Remove Attachment">
                                                        close
                                                    </mat-icon>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Text">
                                            <mat-form-field class="full-width">
                                                <input matInput
                                                       type="text"
                                                       name="{{formItem.formDataType}}_{{formItem.id}}"
                                                       formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                       autocomplete="off" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.TextArea">
                                            <mat-form-field class="full-width">
                                                <textarea matInput
                                                          type="text"
                                                          name="{{formItem.formDataType}}_{{formItem.id}}"
                                                          formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                          autocomplete="off">
                                            </textarea>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.CheckBox">
                                            <mat-checkbox name="{{formItem.formDataType}}_{{formItem.id}}"
                                                          formControlName="{{formItem.formDataType}}_{{formItem.id}}">
                                                <span [innerHTML]="formItem.paragraph | safeHtml">
                                                </span>
                                            </mat-checkbox>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Email">
                                            <mat-form-field class="full-width">
                                                <input matInput
                                                       type="email"
                                                       name="{{formItem.formDataType}}_{{formItem.id}}"
                                                       formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                       autocomplete="off" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Phone">
                                            <mat-form-field class="full-width">
                                                <input matInput
                                                       type="tel"
                                                       name="{{formItem.formDataType}}_{{formItem.id}}"
                                                       formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                       autocomplete="off" />
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Date" style="margin-bottom: 15px;">
                                            <mat-form-field class="full-width">
                                                <input matInput
                                                       [matDatepicker]="i"
                                                       name="{{formItem.formDataType}}_{{formItem.id}}"
                                                       formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                       autocomplete="off">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matSuffix
                                                                       [for]="i">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #i></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Signature">
                                            <div class="signature-container">
                                                <div class="signature">
                                                    <img *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].value"
                                                         [src]="formGroup.controls[formItem.formDataType + '_' + formItem.id].value"
                                                         title="{{formItem.formDataType + '_' + formItem.id}}"
                                                         class="signature-pad">
                                                    <div class="signature-button-container"
                                                         *ngIf="!isWorking">
                                                        <button mat-stroked-button
                                                                type="button"
                                                                (click)="openSignatureWindow('full', formItem.formDataType + '_' + formItem.id)">
                                                            <mat-icon>history_edu</mat-icon>
                                                            <span style="font-size:10px;">SIGN HERE</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="formItem.formDataType == formDataTypeEnum.Number">
                                            <mat-form-field>
                                                <input matInput
                                                       type="number"
                                                       name="{{formItem.formDataType}}_{{formItem.id}}"
                                                       formControlName="{{formItem.formDataType}}_{{formItem.id}}"
                                                       autocomplete="off" />
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id] &&
                                                formGroup.controls[formItem.formDataType + '_' + formItem.id].invalid &&
                                                formGroup.controls[formItem.formDataType + '_' + formItem.id].errors">
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('required')">
                                                <ng-container *ngIf="formItem.formDataType != formDataTypeEnum.Date">This field is required.</ng-container>
                                                <ng-container *ngIf="formItem.formDataType == formDataTypeEnum.Date">Please input a valid date.</ng-container>
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('minlength')">
                                                The minimum length for this field is {{formItem.minimunLength}} characters.
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('maxlength')">
                                                The maximum length for this field is {{formItem.maximumLength}} characters.
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('email')">
                                                Please input a valid email.
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('pattern')">
                                                Please input a valid phone number.
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('min')">
                                                The minimum number for this field is {{formItem.minimunLength}}.
                                            </small>
                                            <small class="error-info"
                                                   *ngIf="formGroup.controls[formItem.formDataType + '_' + formItem.id].hasError('max')">
                                                The maximum number for this field is {{formItem.maximumLength}}.
                                            </small>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-template>
                        </ng-container>
                    </div>

                    <div *ngIf="stationaryFooter"
                         class="form-footer"
                         [innerHTML]="stationaryFooter">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="form-action-button-container d-flex justify-content-between">
                    <button mat-mini-fab
                            color="warn"
                            type="button"
                            (click)="cancel()">
                        <mat-icon>close</mat-icon>
                    </button>

                    <div *ngIf="selectedFormType.formSections.length > 1">
                        <button mat-mini-fab
                                color="primary"
                                type="button"
                                [disabled]="page == 1"
                                (click)="page=page-1">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                        </button>
                        <span class="page-info"> {{page}} of {{selectedFormType.formSections.length}} </span>
                        <button mat-mini-fab
                                color="primary"
                                type="button"
                                [disabled]="page == selectedFormType.formSections.length"
                                (click)="page=page+1">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>

                    <button mat-mini-fab
                            color="primary"
                            [disabled]="isWorking || formGroup.invalid"
                            type="submit">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
</ng-container>
