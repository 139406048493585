import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractsComponent } from '../contracts/contracts.component';
import { EvoValidatorsModule } from '../shared/evolution-form-validators';
import { QuoteComponent } from './quote/quote.component';
import { SignContractComponent } from './sign-contract/sign-contract.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { SignatureDialogModule } from '../shared/signature-dialog/signature-dialog.module';

const routes: Routes = [
    {
        path: '',
        component: ContractsComponent,
        children: [
            {
                path: 'sign',
                component: SignContractComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'quote',
                component: QuoteComponent,
            },
            {
                path: 'thankyou',
                component: ThankYouComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [
        ContractsComponent,
        ThankYouComponent,
        SignContractComponent,
        QuoteComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatRadioModule,
        MatListModule,
        MatListModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatSnackBarModule,
        EvoValidatorsModule,
        MatCardModule,
        MatSliderModule,
        SignatureDialogModule
    ],
    entryComponents: [],
})
export class ContractsModule { }
