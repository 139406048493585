import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DigitalSignatureModule } from '../digital-signature/digital-signature.module';
import { SignatureDialogComponent } from './signature-dialog.component';


@NgModule({
    imports: [
        DigitalSignatureModule,
        MatDialogModule,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    exports: [SignatureDialogComponent],
    declarations: [SignatureDialogComponent],
    providers: [],
})
export class SignatureDialogModule { }
