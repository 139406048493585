import { Injectable } from '@angular/core';
import { TenantTokenSessionItem } from '../../models/auth';
import { PaymentSelectionTypeEnum, PortalTypeEnum, TokenDto } from '../api.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private _setBrowserStorage: Function = (key: string, value: string) => sessionStorage.setItem(key, value);
    private _getBrowserStorage: Function = (key: string) => sessionStorage.getItem(key);
    private _removeBrowserStorage: Function = (key: string) => sessionStorage.removeItem(key);
    private _clearBrowserStorage: Function = () => sessionStorage.clear();

    private _maskedTenantKey = 'maskedTenantKey';
    private _tenant = 'tenant';
    private _token = 'token';
    private _tokenInfo = 'tokenInfo';
    private _selectedTokenIdInfo = 'selectedTokenIdInfo';
    private _selectedTokenId = 'selectedTokenId';

    constructor() { }

    public getBrowserTokenStorage(): [Object[], string, string, TenantTokenSessionItem?] {
        return [
            JSON.parse(this._getBrowserStorage(this._token)),
            this._getBrowserStorage(this._selectedTokenId),
            this._getBrowserStorage(this._maskedTenantKey),
            JSON.parse(this._getBrowserStorage(this._tenant))
        ];
    }

    public removeBrowserTokenStorage() {
        this._removeBrowserStorage(this._maskedTenantKey);
        this._removeBrowserStorage(this._tenant);
        this._removeBrowserStorage(this._token);
        this._removeBrowserStorage(this._tokenInfo);
        this._removeBrowserStorage(this._selectedTokenIdInfo);
    }

    public getPortalType(): PortalTypeEnum {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.portalType ?? PortalTypeEnum.Unknown : PortalTypeEnum.Unknown;
    }

    public getAppointmentId(): number {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.appointmentId : null;
    }

    public getContactId(): number {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.contactId : null;
    }

    public getPatientId(): number {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.patientId : null;
    }

    public getMessageId(): number {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.messageId : null;
    }

    public getPrescribedDate(): Date {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.prescribedDate : null;
    }

    public getContractPlanId(): number {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.contractPlanId : null;
    }

    public getIsQuote(): boolean {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.isQuote : false;
    }

    public getPaymentMethod(): PaymentSelectionTypeEnum {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0]?.paymentMethod : null;
    }

    public setPaymentMethod(paymentMethod: PaymentSelectionTypeEnum): void {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        if (!token || !token[0]) return;
        token[0].paymentMethod = paymentMethod;
        this._setBrowserStorage(this._token, JSON.stringify(token));
    }

    public setContractPlanId(id: number): void {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        if (!token || !token[0]) return;
        token[0].contractPlanId = id;
        this._setBrowserStorage(this._token, JSON.stringify(token));
    }

    public clearBrowserStorage() {
        this._clearBrowserStorage();
    }

    public setBrowserMaskedTenantKey(maskedTenantKey: string) {
        this._setBrowserStorage(this._maskedTenantKey, maskedTenantKey);
    }

    public getBrowserMaskedTenantKey(): string {
        return this._getBrowserStorage(this._maskedTenantKey);
    }

    public getBrowserToken(): TokenDto {
        let token = JSON.parse(this._getBrowserStorage(this._token));
        return token ? token[0] : null;
    }

    public setBrowserTenantStorage(tenant: TenantTokenSessionItem) {
        this._setBrowserStorage(this._tenant, JSON.stringify(tenant));
    }

    public setBrowserTokenStorage(token: any) {
        this._setBrowserStorage(this._token, JSON.stringify(token));
    }

    public setBrowserTokenInfo(token: any, selectedTokenId: string) {
        this._setBrowserStorage(this._tokenInfo, JSON.stringify(token));
        this._setBrowserStorage(this._selectedTokenIdInfo, selectedTokenId);
    }

    public setBrowserSelectedTokenStorage(selectedTokenId: string) {
        this._setBrowserStorage(this._selectedTokenId, selectedTokenId);
    }
}
