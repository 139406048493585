<h1 mat-dialog-title
    [hidden]="isTextSignature">
    Please sign with your {{(this.data.type == 'full' ? 'First & Last Name' : 'Initials')}}
</h1>

<h1 mat-dialog-title
    [hidden]="!isTextSignature">
    Please sign with your name
</h1>

<mat-dialog-content>
    <digital-signature [size-ratio]="this.data.type == 'full' ? .25 : .75"
                       [hidden]="isTextSignature"
                       #signature>
    </digital-signature>
    <digital-signature [size-ratio]=".25"
                       [hidden]="!isTextSignature"
                       #signatureTextElement>
    </digital-signature>
    <digital-signature [size-ratio]=".25"
                       [hidden]="!isTextSignature"
                       #signatureInitialsElement>
    </digital-signature>

    <button mat-button
            [hidden]="isTextSignature"
            (click)="signature.clear()">
        Reset
    </button>

    <mat-form-field [hidden]="!isTextSignature">
        <mat-label>Signature Text</mat-label>
        <input type="text"
               #signatureTextInput
               matInput
               [(ngModel)]="signatureText" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button (click)="done()">Done</button>
</mat-dialog-actions>
