import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RootStoreState } from '../root-store';

@UntilDestroy()
@Component({
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit {
  constructor(activatedRoute: ActivatedRoute, private _store$: Store<RootStoreState.State>, private _router: Router) {
    activatedRoute.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      const tenant = params.get('tenant');
      const token = params.get('token');
      if (tenant && token) {
        const isQuote = JSON.parse(params.get('isQuote').toLowerCase());
        const planOption = params.get('planOption');
        const returnUrl = this._router.serializeUrl(
          this._router.createUrlTree(['/patient', 'contracts', isQuote ? 'quote' : 'sign', token], {
            queryParams: { planOption: planOption },
          })
        );
        this._router.navigate(['/patient', 'login'], { queryParams: { returnUrl: returnUrl }, queryParamsHandling: 'merge' });
      }
    });
  }

  ngOnInit() {}
}
