import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MomentDateModule } from '../../../pipe/moment-date/moment-date.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MomentOrdinalDateModule } from 'src/app/pipe/moment-ordinal-date/moment-ordinal-date.module';
import { PatientRescheduleComponent } from './patient-reschedule.component';

@NgModule({
	declarations: [PatientRescheduleComponent],
	exports: [PatientRescheduleComponent],
	imports: [
		CommonModule,
		FormsModule,
		MomentDateModule,
        MomentOrdinalDateModule,
		MatDialogModule,
		MatProgressBarModule,
        MatButtonModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
	]
})
export class PatientRescheduleModule { }
